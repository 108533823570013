import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import AccordionContext from "react-bootstrap/AccordionContext"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons"
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons"

const SectionBackground = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  padding-bottom: 50px;
`

const CardBody = styled(Card.Body)`
  font: 20px/22px Roboto;
`

const AccordionSubtitle = styled.div`
  font: 28px/32px Abril Fatface;
  color : ${props => props.theme.colors.gray_dark};
`

const CardAccordion = styled(Card)`
  border: 2px solid ${props => props.theme.colors.gray_border};
  border-left: none;
  border-right: none;
  .card-header {
    padding: 0;
    background-color: ${props => props.theme.colors.white};
    border-bottom: none;
  }
  .card-body {
    text-align: justify;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 15px;
    span {
      margin-left: 15px;
    }
    div {
      margin-top: 10px;
    }
  }
  .row-video {
    img {
      width: 122px;
      height: 70px;
      object-fit: contain;
    }
  }
  .col-video {
    @media (min-width: 1200px) {
      padding-right: 0;
    }
  }
`
const FAArrow = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary_violet};
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${props => props.theme.colors.primary_violet_dark};
  }
`
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div
      css={css`
        font: Bold 20px/28px Roboto;
        outline:none;
        padding-top:30px;
        padding-bottom:30px};
        @media(max-width:767px){
          padding-top:15px;
          padding-bottom:15px};
        }
      `}
      onClick={decoratedOnClick}
      onKeyDown={decoratedOnClick}
      role="button"
      tabIndex={0}
    >
      <Row>
        <Col xs="10" md="11">
          {children}
        </Col>
        <Col xs="2" md="1" className="d-flex justify-content-end">
          <FAArrow
            icon={
              isCurrentEventKey ? faArrowAltCircleDown : faArrowAltCircleRight
            }
          />
        </Col>
      </Row>
    </div>
  )
}

export default function FaqSection9() {
  let activeKey = "0";

  if (typeof window !== `undefined`) {  
    let params = new URLSearchParams(document.location.search);
    let getactiveKey = params.get("defaultActiveKey");
    console.log("activekey:", activeKey);
    if (getactiveKey!==null){
      activeKey = getactiveKey;
    }
  }
useEffect(() => {
  if (typeof window !== `undefined`) {
  
  let params = new URLSearchParams(document.location.search);
  let getanchorid = params.get("id");
    
    if (getanchorid != null){
      let anchorid = getanchorid;
      console.log("anchorid:", anchorid);
      let ref = document.getElementById(anchorid);
      ref.scrollIntoView({
        behavior:'smooth',
        block:'center',
        inline:'center'
      });
      console.log("scrolling");
  }
}
});
  return (
    <SectionBackground>
      <Container>
        <Row>
          <Col lg="12">
            <Accordion
              defaultActiveKey={activeKey}
              css={css`
                margin-bottom: 25px;
              `}
            >
              {/* <AccordionTitle>Volunteers and Interns</AccordionTitle> */}
              <AccordionSubtitle>Volunteers and Interns</AccordionSubtitle>
              <CardAccordion id="section9-point1">
                <Card.Header>
                  <ContextAwareToggle eventKey="0">
                    Is WomenWorks open for interns and volunteers?
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <CardBody>
                    We are always welcome for new team members! Should you be
                    interested, please send your CV and cover letter to&nbsp;
                    <a href="mailto:yours@womenworks.io">
                      yours@womenworks.io
                    </a>
                    . All applications will be assessed by our leadership team.
                    Acceptance and placement will be subject to the team’s
                    needs.
                    <br />
                    <br />
                    Get to know our divisions and team members at&nbsp;
                    <Link to={`/who-we-are/`}>
                      http://womenworks.io/who-we-are.
                    </Link>
                  </CardBody>
                </Accordion.Collapse>
              </CardAccordion>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </SectionBackground>
  )
}
