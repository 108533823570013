import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/faq-section1";
import Section2 from "../sections/faq-section2";
import Section4 from "../sections/faq-section4";
import Section5 from "../sections/faq-section5";
import Section7 from "../sections/faq-section7";
import Section8 from "../sections/faq-section8";
import Section9 from "../sections/faq-section9";
import TableofContent from "../sections/faq-tableofcontent";
import TitleSection from "../sections/faq-title";
import SEO from "../components/seo";
import Col from "react-bootstrap/Col";
import Row from 'react-bootstrap/Row';
import {css} from "@emotion/core";
import Container from 'react-bootstrap/Container';

export default function SignUpforMentorship() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >
        <SEO title={"Frequently Asked Questions"} description={"Questions about mentorship and WomenWork's services"} />
        <Container>
          <TitleSection></TitleSection>
          <Row css={css`
                  display:flex;
                  justify-content: center;`}>
            <Col lg="3">
              <TableofContent></TableofContent>
            </Col>
            <Col lg="9">
            <Section1></Section1>
            <Section5></Section5>
            <Section2></Section2>
            <Section8></Section8>
            <Section4></Section4>
            <Section7></Section7>
            <Section9></Section9>
            </Col>
          </Row>
        </Container>
    </Layout>   
  )
}
