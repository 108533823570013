import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import styled from "@emotion/styled";
import {Global, css} from "@emotion/core";
import Container from 'react-bootstrap/Container';

const IndexSection2Section1Background = styled.div`
  background-color: ${props => props.theme.colors.white_dark};
  padding-top:12px;
  padding-bottom:30px;
  padding-left:12px;
  padding-right:12px;
`

const ContentTitle = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: bold 16px/18px Roboto;
  margin-left:-10px;
  margin-bottom:12px;
  margin-top : 18px;
`
const ContentSubtitle = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: bold 16px/18px Roboto;
  margin-bottom:10px;
`

const SectionText = styled.div`
  font: bold 14px/18px Roboto;
  padding-left:8px;
`

export default function IndexSection2Section1(props) {
  return (
  <IndexSection2Section1Background css={css`
  @media(max-width:991px) {
    display: none;
  }`}>
      <Global styles={css`
      .button-content{
        border:none;
        text-align:left;
        color: #8D589B;
        font: 14px/18px Roboto;
        margin-bottom: 12px;
        background: transparent;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    `}/>
      <Container>
          <ContentTitle>
            WHAT WE DO
          </ContentTitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section1-point1'></input>
                  <button className='button-content' type='submit'>1. Who is WomenWorks?</button>
                </form>
            </Row>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='1'></input>
                  <input type='hidden' id='id' name='id' value='section1-point2'></input>
                  <button className='button-content' type='submit'>2. What is women-first? Can you cater products for males in the work-force?</button>
                </form>
            </Row>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='2'></input>
                  <input type='hidden' id='id' name='id' value='section1-point3'></input>
                  <button className='button-content' type='submit'>3. How does WomenWorks accelerate growth and ensure relevancy for the women in our network?</button>
                </form>
            </Row>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='3'></input>
                  <input type='hidden' id='id' name='id' value='section1-point4'></input>
                  <button className='button-content' type='submit'>4. Why WomenWorks?</button>
                </form>
            </Row>
          </SectionText>
          <ContentTitle>
          WOMENWORKS SERVICES
          </ContentTitle>
          <ContentSubtitle>
          Pre-Mentorship
          </ContentSubtitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section5-point1'></input>
                  <button className='button-content' type='submit'>1. Who is this best for?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='1'></input>
                  <input type='hidden' id='id' name='id' value='section5-point2'></input>
                  <button className='button-content' type='submit'>2. How does it work?</button>
                </form>
            </Row>
          </SectionText>
          <ContentSubtitle>
          What is mentorship?
          </ContentSubtitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section2-point1'></input>
                  <button className='button-content' type='submit'>1. What can I get out of a mentorship session?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='1'></input>
                  <input type='hidden' id='id' name='id' value='section2-point2'></input>
                  <button className='button-content' type='submit'>2. How can a mentorship help me? What should I be expecting?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='2'></input>
                  <input type='hidden' id='id' name='id' value='section2-point3'></input>
                  <button className='button-content' type='submit'>3. What is discussed in a mentorship? Is there a set curriculum?</button>
                </form>
            </Row>
          </SectionText>
          <ContentSubtitle>
          1:1 Mentorship
          </ContentSubtitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section8-point1'></input>
                  <button className='button-content' type='submit'>1. Who is a mentorship best for?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='1'></input>
                  <input type='hidden' id='id' name='id' value='section8-point2'></input>
                  <button className='button-content' type='submit'>2. How does it work?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='2'></input>
                  <input type='hidden' id='id' name='id' value='section8-point3'></input>
                  <button className='button-content' type='submit'>3. Can we choose our mentors, or will WomenWorks pick one for us?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='3'></input>
                  <input type='hidden' id='id' name='id' value='section8-point4'></input>
                  <button className='button-content' type='submit'>4. If we book multiple 1:1 sessions, are we allowed to change our mentors midway?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='4'></input>
                  <input type='hidden' id='id' name='id' value='section8-point5'></input>
                  <button className='button-content' type='submit'>5. When will my mentoring sessions be conducted?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='5'></input>
                  <input type='hidden' id='id' name='id' value='section8-point6'></input>
                  <button className='button-content' type='submit'>6. Why do I need to send a video? Can I send my CV/written profile/etc. instead?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='6'></input>
                  <input type='hidden' id='id' name='id' value='section8-point7'></input>
                  <button className='button-content' type='submit'>7. What happens if I cannot commit immediately to a mentorship after I have paid and sent my video? Am I allowed to reserve my sessions?</button>
                </form>
            </Row>
          </SectionText>
          <ContentSubtitle>
          Masterclass
          </ContentSubtitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section4-point1'></input>
                  <button className='button-content' type='submit'>1. Who is this best for?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='1'></input>
                  <input type='hidden' id='id' name='id' value='section4-point2'></input>
                  <button className='button-content' type='submit'>2. How does it work?</button>
                </form>
            </Row>
          </SectionText>
          <ContentTitle>
          MISCELLANEOUS
          </ContentTitle>
          <ContentSubtitle>
          Mentors
          </ContentSubtitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section7-point1'></input>
                  <button className='button-content' type='submit'>1. Can I be a mentor in WomenWorks?</button>
                </form>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='1'></input>
                  <input type='hidden' id='id' name='id' value='section7-point2'></input>
                  <button className='button-content' type='submit'>2. What perks and responsibilities do I have as a WomenWorks mentor?</button>
                </form>
            </Row>
          </SectionText>
          <ContentSubtitle>
          Volunteers and Interns
          </ContentSubtitle>
          <SectionText>
            <Row>
                <form action='/faq' method='GET'>
                  <input type='hidden' id='defaultActiveKey' name='defaultActiveKey' value='0'></input>
                  <input type='hidden' id='id' name='id' value='section9-point1'></input>
                  <button className='button-content' type='submit'>Is WomenWorks open for interns and volunteers?</button>
                </form>
            </Row>
          </SectionText>
      </Container>
  </IndexSection2Section1Background>
  )
}

