import React, { useContext, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const SectionBackground = styled.div`
  background-color : ${props => props.theme.colors.white};
  color : ${props => props.theme.colors.gray_dark};
  padding-bottom:50px;
`

const CardBody = styled(Card.Body)`
  font: 20px/22px Roboto;
`

const AccordionTitle = styled.div`
  font: 58px/62px Abril Fatface;
  color : ${props => props.theme.colors.secondary_green};
  margin-bottom: 10px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
  }
`

const CardAccordion = styled(Card)`
  border: 2px solid ${props => props.theme.colors.gray_border};
  border-left:none;
  border-right:none;
  .card-header {
    padding:0;
    background-color: ${props => props.theme.colors.white};
    border-bottom:none;
  }
  .card-body {
    text-align: justify;
    padding-left:5px;
    padding-top:0;
    padding-bottom:15px;
    span {
      margin-left:15px;
    }
    div {
      margin-top:10px;
    }
  }
  .row-video {
    img {
      width:122px;
      height:70px;
      object-fit:contain;
    }
  }
  .col-video{
    @media(min-width:1200px) {
      padding-right:0;
    }
  }
`
const FAArrow = styled(FontAwesomeIcon)`
  font-size:1.5rem;
  color: ${props => props.theme.colors.primary_violet};
  &:hover, &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.primary_violet_dark};
  }
`

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      css={css`
        font: Bold 20px/28px Roboto;
        outline:none;
        padding-top:30px;
        padding-bottom:30px};
        @media(max-width:767px){
          padding-top:15px;
          padding-bottom:15px};
        }
      `}
      onClick={decoratedOnClick}
      onKeyDown={decoratedOnClick}
      role="button"
      tabIndex={0}
    >
      <Row>
        <Col xs="10" md="11">
          {children}
        </Col>
        <Col xs="2" md="1" className="d-flex justify-content-end">
          <FAArrow icon={isCurrentEventKey ? faArrowAltCircleDown : faArrowAltCircleRight} />
        </Col>
      </Row>
    </div>
  );
}



export default function FaqSection1() {
  let activeKey = "0";

  if (typeof window !== `undefined`) {  
    let params = new URLSearchParams(document.location.search);
    let getactiveKey = params.get("defaultActiveKey");
    console.log("activekey:", activeKey);
    if (getactiveKey!==null){
      activeKey = getactiveKey;
    }
  }
useEffect(() => {
  if (typeof window !== `undefined`) {
  
  let params = new URLSearchParams(document.location.search);
  let getanchorid = params.get("id");
    
    if (getanchorid != null){
      let anchorid = getanchorid;
      console.log("anchorid:", anchorid);
      let ref = document.getElementById(anchorid);
      ref.scrollIntoView({
        behavior:'smooth',
        block:'center',
        inline:'center'
      });
      console.log("scrolling");
  }
}
});

  return (      
    <SectionBackground>
      <Container>
        <Row>
          <Col lg="12">
          <Accordion
              defaultActiveKey={activeKey}
              css={css`
                margin-bottom: 25px;
              `}>
            <AccordionTitle id="title">What we do</AccordionTitle>
            <CardAccordion id="section1-point1">
              <Card.Header>
                <ContextAwareToggle eventKey="0">1. Who is WomenWorks?</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <CardBody>
                A <b>women-first recruiting platform</b> advancing gender diversity by <b>empowering women</b> to embrace their <b>growth mindset</b> in order to accelerate growth and stay relevant in the workforce. 
                </CardBody>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion id="section1-point2">
              <Card.Header>
                <ContextAwareToggle eventKey="1">2. What is women-first? Can you cater products for males in the work-force?</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
              <CardBody>
                Women-first means we put women's growth at our top priority, understanding that this can only happen with the support and sponsorship of male allies in the workforce.
                <br></br><br></br>
                We're more than happy to also support the male growth esp. in diversity and inclusion. All <b>corporate products</b> that are tailored for 1:many audiences can be joined by male employees or participants.
                </CardBody>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion id="section1-point3">
              <Card.Header>
                <ContextAwareToggle eventKey="2">3. How does WomenWorks accelerate growth and ensure relevancy for the women in our network?</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <CardBody>
                Our business and individual products are designed to leverage our network, from 1:1 mentoring, group mentoring, executive leadership coaching, masterclasses, employer and personal branding opportunities, and recruiting, connect them to the hot undiscovered jobs within the network.
                </CardBody>
              </Accordion.Collapse>
            </CardAccordion>
            <CardAccordion id="section1-point4">
              <Card.Header>
                <ContextAwareToggle eventKey="3">4. Why WomenWorks?</ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <CardBody>
                    We connect you with a diverse lineup of mentors: from C-level professionals, consultants, entrepreneurs, life coaches, financial analysts, marketing experts, communication specialists, public policy analysts, lawyers, architects, journalists, counselors, and so on. Each of them has over 5 years of work experience in reputable companies, which gives them knowledge like no other in their respective industries and roles.
                    <br></br><br></br>
                    Our individual programs are risk-free.* 
                    <br></br><br></br>
                    *If any of our mentees are not satisfied with their mentorship experience with us, we are able to provide refunds on a case-by-case basis.
                </CardBody>
              </Accordion.Collapse>
            </CardAccordion>
          </Accordion>
          </Col>
        </Row>
      </Container>
    </SectionBackground>
  )
}