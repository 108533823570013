import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styled from "@emotion/styled";

const SectionBackground = styled.div`
  background-color : ${props => props.theme.colors.white};
  color : ${props => props.theme.colors.gray_dark};
  padding-bottom:50px;
  @media(max-width:767px){
    padding-bottom:20px;
  }
`
const TitleText = styled.div`
  color : ${props => props.theme.colors.gray_dark};
  text-align: center;
  font: 58px/62px Abril Fatface;
  padding-top:85px;
  margin-bottom:50px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    padding-top:30px;
    margin-bottom:30px;
  }
`
export default function FaqSection1() {
  return (      
    <SectionBackground>
      <Container>
        <Row>
            <TitleText>
                Frequently Asked Questions
            </TitleText>
        </Row>
      </Container>
    </SectionBackground>
  )
}